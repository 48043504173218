import { CustomCheckbox } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import {
    AutocompleteSearchProps,
    Root,
    SearchBar,
} from '@cfra-nextgen-frontend/shared/src/components/Form/shared/Autocomplete';
import { StringKeyValueItemWithData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import {
    RowWrapper,
    ScrollableColumn,
} from '@cfra-nextgen-frontend/shared/src/components/layout/ScrollableColumn/ScrollableColumn';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { Box, CheckboxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

const fontColor = '#0B2958';

export const Label = styled('label')(() => ({
    color: fontColor,
    fontFamily: fontFamilies.GraphikSemibold,
    fontSize: '15px',
    display: 'block',
    lineHeight: '15px',
    paddingTop: '14.5px',
    paddingBottom: '14.5px',
}));

export const TextContainer = styled('div')(() => ({
    paddingLeft: '20.45px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '0px',
    overflow: 'hidden',
}));

export const OptionLabel = styled('label')(() => ({
    color: fontColor,
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '16px',
    display: 'block',
    paddingTop: '12.2px',
    paddingBottom: '12.2px',
    textWrap: 'wrap',
}));

export default function AutocompletePicklist(props: AutocompleteSearchProps<StringKeyValueItemWithData>) {
    const [inputValue, setInputValue] = useState('');
    const { getRootProps, getInputProps, getOptionProps, groupedOptions, focused, setAnchorEl } = useAutocomplete({
        id: props.id,
        open: true,
        defaultValue: props.defaultValues,
        multiple: true,
        options: props.options,
        getOptionLabel: (option) => option.value,
        onChange: props.onChange,
        value: props.value,
        isOptionEqualToValue: (option, value) => option.key === value.key,
        clearOnBlur: false,
        inputValue: inputValue,
        onInputChange: (event, newInputValue, reason) => {
            if (reason === 'reset') {
                return; // prevent input from clearing on option select
            }
            setInputValue(newInputValue);
        },
        filterOptions: (options, { inputValue }) =>
            options.filter((option) => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1),
    });
    return (
        <Root>
            <SearchBar
                focused={focused}
                inputProps={getInputProps()}
                placeholder={props.placeholder}
                inputValue={inputValue}
                setInputValue={setInputValue}
                setAnchorEl={setAnchorEl}
                rootProps={getRootProps()}
                inputStyle={{
                    width: '100%',
                }}
            />
            <ScrollableColumn
                label={
                    <TextContainer>
                        <Label>{props.label}</Label>
                    </TextContainer>
                }>
                {(groupedOptions as Array<StringKeyValueItemWithData>).map((option, index) => {
                    const optionProps = getOptionProps({
                        option,
                        index,
                    }) as unknown as CheckboxProps; // required to do such conversion, due to getOptionProps return li props

                    return (
                        <RowWrapper
                            key={index}
                            rowProps={optionProps as React.HTMLAttributes<HTMLDivElement>}
                            childrenContainerSx={{ paddingLeft: '8px' }}>
                            <CustomCheckbox
                                {...optionProps}
                                checked={Boolean(optionProps['aria-selected'])}
                                sx={{ width: '24px', height: '24px', margin: '9px' }}
                            />
                            <Box sx={{ paddingLeft: '8px' }}>
                                <OptionLabel>{option.value}</OptionLabel>
                            </Box>
                        </RowWrapper>
                    );
                })}
                {groupedOptions.length === 0 ? (
                    <RowWrapper key={1}>
                        <TextContainer>
                            <OptionLabel>No Results</OptionLabel>
                        </TextContainer>
                    </RowWrapper>
                ) : null}
            </ScrollableColumn>
        </Root>
    );
}

import { HorizontalPagePaddingString } from '@cfra-nextgen-frontend/shared/src/utils';
import Box from '@mui/material/Box';
import React from 'react';

type Props = {
    children: React.ReactNode;
    style?: Object;
    paddingTop?: number;
    paddingBottom?: number;
};

export function ETFTabContainer({ children, style, paddingTop = 32, paddingBottom = 72 }: Props) {
    return (
        <Box
            sx={{
                ...{
                    backgroundColor: '#F2F5FC',
                    padding: `${paddingTop}px ${HorizontalPagePaddingString} ${paddingBottom}px ${HorizontalPagePaddingString}`,
                },
                ...style,
            }}>
            {children}
        </Box>
    );
}

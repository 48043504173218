import { MaxPageWidthString } from '@cfra-nextgen-frontend/shared/src/utils';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { clientSideLogger } from 'lib/aws-rum-web-client';
import React from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
    children: React.ReactNode;
    removeTopMargin?: boolean;
    containerStyles?: SxProps;
};

export function ETFPageContainer({ containerStyles, children }: Props) {
    const location = useLocation();
    React.useEffect(() => {
        clientSideLogger.recordPageView(location.pathname);
    }, [location]);

    const defaultContainerStyles = {
        maxWidth: MaxPageWidthString,
        minHeight: '900px',
    };

    return (
        <Box sx={{ backgroundColor: '#f2f5fc', display: 'flex', flex: 1 }}>
            <Container maxWidth={false} disableGutters sx={{ ...defaultContainerStyles, ...containerStyles }}>
                {children}
            </Container>
        </Box>
    );
}
